import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROUTES_CONFIG } from 'configs/RoutesConfig';

const PublicRoute = () => {
  const { token } = useSelector((state) => state.auth);

  return token ? (
    <Navigate to={ROUTES_CONFIG.AUTHENTICATED_ENTRY} />
  ) : (
    <Outlet />
  );
};

export default PublicRoute;
