import axios from 'axios';
import { API_BASE_URL, REFRESH_TOKEN_API } from 'configs/ApiConfig';
import { AUTH_TOKEN, REFRESH_TOKEN } from 'constants/AppConstant';
import store from '../store';
import { signOut } from 'store/slices/authSlice';

const apiClient = axios.create({
  baseURL: API_BASE_URL,
});

// Request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(AUTH_TOKEN);

    if (token && !config.skipAuth) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem(REFRESH_TOKEN);
        const response = await axios.post(REFRESH_TOKEN_API, {
          refresh_token: refreshToken,
        });
        const newToken = response.data.access_token;

        localStorage.setItem(AUTH_TOKEN, newToken);

        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return apiClient(originalRequest);
      } catch (refreshError) {
        store.dispatch(signOut());
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
