import React from 'react';

export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const ROUTES_CONFIG = {
  UNAUTHENTICATED_ENTRY: `${AUTH_PREFIX_PATH}/login`,
  AUTHENTICATED_ENTRY: `${APP_PREFIX_PATH}/call-list`,
  LOGIN: `${AUTH_PREFIX_PATH}/login`,
  CALL_LIST: `${APP_PREFIX_PATH}/call-list`,
  CALL_DETAIL: `${APP_PREFIX_PATH}/call-detail`,
  PROMPT_MANAGEMENT: `${APP_PREFIX_PATH}/prompt-management`,
};

export const publicRoutes = [
  {
    key: 'login',
    path: ROUTES_CONFIG.LOGIN,
    component: React.lazy(() => import('views/auth-views/login')),
  },
];

export const protectedRoutes = [
  {
    key: 'call-list',
    path: ROUTES_CONFIG.CALL_LIST,
    component: React.lazy(() => import('views/app-views/call-list')),
  },
  {
    key: 'call-detail',
    path: `${ROUTES_CONFIG.CALL_DETAIL}/:id`,
    component: React.lazy(() => import('views/app-views/call-detail')),
  },
  {
    key: 'prompt-management',
    path: ROUTES_CONFIG.PROMPT_MANAGEMENT,
    component: React.lazy(() => import('views/app-views/prompt-management')),
  },
];
