import { Flex } from 'antd';
import React from 'react';

function NotFound() {
  return (
      <Flex
        justify="center"
        align="center"
        gap={10}
        className="font-size-xxl"
        wrap
      >
        404 - Page Not Found
      </Flex>
  );
}

export default NotFound;
