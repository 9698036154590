// src/views/InactivityTimer.js
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'store/slices/authSlice';

const InactivityTimer = () => {
  const dispatch = useDispatch();
  const timer = useRef(null);
  const token = useSelector((state) => state.auth.token); // Get the token from the Redux store

  const handleActivity = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(
      () => {
        dispatch(signOut());
      },
      1000 * 60 * 60
    );
  };

  useEffect(() => {
    if (!token) return; // If there is no token, the user is not logged in

    const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'];

    events.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    // Set the initial timer
    handleActivity();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [dispatch, token]);

  return null;
};

export default InactivityTimer;
