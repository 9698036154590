import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  callId: null,
  callDetails: {},
};

export const callDataSlice = createSlice({
  name: 'callData',
  initialState,
  reducers: {
    setCallId: (state, action) => {
      state.callId = action.payload;
    },
    setCallDetails: (state, action) => {
      state.callDetails = action.payload;
    },
  },
});

export const { setCallId, setCallDetails } = callDataSlice.actions;

export default callDataSlice.reducer;
