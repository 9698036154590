import { BLUE_BASE, GOLD_BASE } from './ThemeConstant';

export const USER_DATA = 'userData';
export const AUTH_TOKEN = 'authToken';
export const REFRESH_TOKEN = 'refreshToken';
export const CALL_IDS = 'callIds';
export const APPLIED_FILTERS = 'appliedFilters';
export const TABLE_PARAMS = 'tableParams';
export const PROMPT_FILTER = 'promptFilter';

export const REDIRECT_URL_KEY = 'redirect';
export const CALL_REPROCESS_ICON = 'callReprocessIcon';
export const CALL_REPROCESS_BUTTON = 'callReprocessButton';
export const CALL_REPROCESS_ACTION_FULL = 'full';
export const CALL_REPROCESS_ACTION_PARTIAL = 'partial';

export const PENDING = 'pending';
export const IN_PROGRESS = 'inprogress';
export const COMPLETED = 'completed';
export const FAILED = 'failed';
export const NEUTRAL = 'neutral';
export const WATCH = 'watch';
export const POSITIVE = 'positive';
export const NEGATIVE = 'negative';

export const STATUS_VALUES_CONFIG = {
  pending: {
    label: 'Pending',
    color: 'purple',
  },
  inprogress: {
    label: 'In Progress',
    color: 'gold',
  },
  completed: {
    label: 'Completed',
    color: 'success',
  },
  failed: {
    label: 'Failed',
    color: 'red',
  },
};

export const SENTIMENT_VALUES_CONFIG = {
  neutral: {
    label: 'Neutral',
    color: 'purple',
  },
  watch: {
    label: 'Watch',
    color: 'gold',
  },
  positive: {
    label: 'Positive',
    color: 'success',
  },
  negative: {
    label: 'Negative',
    color: 'red',
  },
};

export const SECTION_AND_TABS_CONFIG = {
  BASIC_DATA: 'basic_data',
  DISPOSITION: 'disposition',
  SENTIMENT: 'sentiment',
  CALL_SUMMARY: 'call_summary',
  SERVICE_RATINGS: 'service_rating',
  AGENT_RATINGS: 'agent_rating',
  TRANSCRIPT: 'transcript',
};

export const SECTION_API_KEY = {
  DISPOSITION: 'disposition',
  CALL_SUMMARY: 'call_summary',
  SENTIMENT: 'sentiment',
  SERVICE_RATING: 'service_rating',
  AGENT_RATING: 'agent_rating',
  TRANSCRIBE: 'transcribe' || 'speaker_dairaization',
  SPEAKER_DAIRAIZATION: 'speaker_dairaization',
};

export const SECTION_ENABLED_KEY = {
  DISPOSITION: 'is_disposition_enabled',
  CALL_SUMMARY: 'is_call_summary_enabled',
  SENTIMENT: 'is_sentiment_enabled',
  SERVICE_RATING: 'is_call_rating_enabled',
  AGENT_RATING: 'is_agent_rating_enabled',
};

export const CALL_STANDARD_OPTIONS = {
  NORMAL: 'normal',
  GOLD_STANDARD_CANDIDATE: 'gold_standard_candidate',
  GOLD_STANDARD_CALL: 'gold_standard',
};

export const CALL_STANDARD_COLOR = {
  normal: 'transparent',
  gold_standard_candidate: BLUE_BASE,
  gold_standard: GOLD_BASE,
};

export const CALL_STANDARD_LABEL = {
  normal: 'Normal',
  gold_standard_candidate: 'Gold Candidate',
  gold_standard: 'Gold Standard',
};

export const RATING_COLOR = {
  0: 'blue',
  1: 'pink',
  2: 'red',
  3: 'orange',
  4: 'yellow',
  5: 'green',
};

export const NEW_RATING_COLOR = {
  1: {
    font: '#B42318',
    border: '#FECDCA',
    background: '#FEF3F2',
    active_background: '#B42318',
  },
  2: {
    font: '#B42318',
    border: '#FECDCA',
    background: '#FEF3F2',
    active_background: '#B42318',
  },
  3: {
    font: '#B54708',
    border: '#FEDF89',
    background: '#FFFAEB',
    active_background: '#FEDF89',
  },
  4: {
    font: '#067647',
    border: '#ABEFC6',
    background: '#ECFDF3',
    active_background: '#067647',
  },
  5: {
    font: '#067647',
    border: '#ABEFC6',
    background: '#ECFDF3',
    active_background: '#067647',
  },
};
