import apiClient from 'services/api-client';
import { LOGIN_API } from 'configs/ApiConfig';

export const login = async (data) => {
  try {
    const response = await apiClient.post(LOGIN_API, data, {
      redirect: 'follow',
      mode: 'cors',
    });

    return response;
  } catch (error) {
    console.error('Error while signing in:', error);
  }
};
